import "./networks.css";
// import { Ballot, Business, People } from "@material-ui/icons";
import NetworkChart from "../networkChart/NetworkChart";

function Networks({ dataQuery, type }) {
  return (
    <div className="networks">
      <div className="networks-header">
        <h2>News Networks</h2>
        {/* <div className="show-hide">
          <b>show/hide:</b>
          <div className="choice">
            <input type="checkbox" name="companies" id="" />
            <Business className="icon" />
            <span>companies,</span>
          </div>
          <div className="choice">
            <input type="checkbox" name="organizations" id="" />
            <Ballot className="icon" />
            <span>organizations,</span>
          </div>
          <div className="choice">
            <input type="checkbox" name="people" id="" />
            <People className="icon" />
            <span>people</span>
          </div>
        </div> */}
      </div>
      <div className="networks-graph">
        <NetworkChart dataQuery={dataQuery} type={type} />
      </div>
    </div>
  );
}

export default Networks;
