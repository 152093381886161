import axios from "axios";
import { useEffect, useState } from "react";
import Details from "../../components/details/Details";
import Header from "../../components/header/Header";
import Locations from "../../components/locations/Locations";
import Networks from "../../components/networks/Networks";
import Timeline from "../../components/timeline/Timeline";
import Topics from "../../components/topics/Topics";
import "./home.css";

function Home() {
  const [query, setQuery] = useState("");
  const [data, setData] = useState("Indonesia");
  const [type, setType] = useState("unconstrained");
  const [isLoading, setIsLoading] = useState(false);

  const [detailData, setDetailData] = useState([]);
  const [detailSosial, setDetailSosial] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    const fetchDetail = async () => {
      // const detail = await axios.get(
      //   `/corpus?corpus=news&environment=null&query=${dataQuery}&type=${type}&language=en&num=100`
      // );
      const detail = await axios.post(
        `https://app01.tmsdev.space/api/datanews`,
        {
          query: data,
        }
      );
      // console.log(detail.data.nodes, "ini data nodes");
      const filterDetail = await detail.data.nodes.filter(
        (el) => el.type === "article"
      );
      // console.log("filterDetail :>> ", filterDetail);
      setDetailData(filterDetail);
      setIsLoading(false);
    };
    fetchDetail();
  }, [data, setIsLoading]);

  useEffect(() => {
    // setIsLoading(true);
    const fetchDetail = async () => {
      // const detail = await axios.get(
      //   `/corpus?corpus=news&environment=null&query=${dataQuery}&type=${type}&language=en&num=100`
      // );
      const detail = await axios.post(
        `https://app01.tmsdev.space/api/datasosialmedia`,
        {
          query: data,
        }
      );
      // console.log(detail.data.nodes, "ini data nodes");
      setDetailSosial(detail.data.nodes);
      // setIsLoading(false);
    };
    fetchDetail();
  }, [data, setIsLoading]);

  return (
    <div className="home">
      <Header
        query={query}
        setQuery={setQuery}
        setData={setData}
        setType={setType}
      />
      <div className="main-content">
        <Details
          title="News"
          detailData={detailData}
          dataQuery={data}
          type={type}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <Details
          title="Medsos"
          detailData={detailSosial}
          dataQuery={data}
          type={type}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
        <Networks dataQuery={data} type={type} />
        <div className="right-content">
          <Locations />
          <Topics dataQuery={data} />
        </div>
      </div>
      <Timeline />
    </div>
  );
}

export default Home;
