import { useEffect, useRef, useState } from "react";
import "./geoChart.css";
import useResizeObserver from "./useResizeObserver";
import { select, geoPath, geoMercator, min, max, scaleLinear } from "d3";
import data from "./GeoChart.world.geo.json";

function GeoChart() {
  const property = "gdp_md_est"; // name_len // gdp_md_est //pop_est
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [selectedCountry, setSelectedCountry] = useState(null);

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current);
    const minProp = min(
      data.features,
      (feature) => feature.properties[property]
    );
    const maxProp = max(
      data.features,
      (feature) => feature.properties[property]
    );
    const colorScale = scaleLinear()
      .domain([minProp, maxProp])
      .range(["#ccc", "red"]);

    // use Resized
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();

    // project geo-coordinates on a 2d plane
    const projection = geoMercator()
      .fitSize([width, height], selectedCountry || data)
      .precision(100);
    // takes geojson data
    // transforms that into the d attribute of path element
    const pathGenerator = geoPath().projection(projection);

    //render each country
    svg
      .selectAll(".country")
      .data(data.features)
      .join("path")
      .on("click", (feature) => {
        setSelectedCountry(selectedCountry === feature ? null : feature);
      })
      .attr("class", "country")
      .transition()
      .attr("fill", (feature) => colorScale(feature.properties[property]))
      .attr("d", (feature) => pathGenerator(feature));
    // render text
    svg
      .selectAll(".label")
      .data([selectedCountry])
      .join("text")
      .attr("class", "label")
      .text(
        (feature) =>
          feature &&
          feature.properties.name +
            ": " +
            feature.properties[property].toLocalString()
      )
      .attr("x", 10)
      .attr("y", 25);
  }, [dimensions, selectedCountry]);

  return (
    <div ref={wrapperRef} className="container-geo">
      <svg ref={svgRef} />
    </div>
  );
}

export default GeoChart;
