import { LinearProgress } from "@material-ui/core";
// import axios from "axios";
// import { useEffect, useState } from "react";
import Detail from "../detail/Detail";
import "./details.css";

function Details({ dataQuery, setIsLoading, isLoading, title, detailData }) {
  // const [detailData, setDetailData] = useState([]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   const fetchDetail = async () => {
  //     // const detail = await axios.get(
  //     //   `/corpus?corpus=news&environment=null&query=${dataQuery}&type=${type}&language=en&num=100`
  //     // );
  //     const detail = await axios.post(
  //       `https://app01.tmsdev.space/api/datanews`,
  //       {
  //         query: dataQuery,
  //       }
  //     );
  //     // console.log(detail.data.nodes, "ini data nodes");
  //     setDetailData(detail.data.nodes);
  //     setIsLoading(false);
  //   };
  //   fetchDetail();
  // }, [dataQuery, setIsLoading]);
  return (
    <div className="details">
      <div className="details-title">
        <h2>{title}</h2>
        <span>
          {detailData.length ? detailData.length - 1 : "0"} articles loaded
        </span>
      </div>
      {/* Card Detail */}
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div className="detail-card">
          {detailData &&
            detailData.map((detailItem) => (
              <Detail
                detailItem={detailItem}
                isLoading={isLoading}
                key={detailItem.id}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default Details;
