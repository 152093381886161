import { useEffect, useRef, useState } from "react";
import { select, min, max, scaleTime, scaleLinear, axisBottom } from "d3";
import "./timeLineChart.css";
import useResizeObserver from "../geoChart/useResizeObserver";

const getDate = (dateString) => {
  const date = dateString.split("-");

  return new Date(date[2], date[0] - 1, date[1]);
};

export default function TimeLineChart() {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const [data, setData] = useState([]);
  // console.log(data, "ini data");

  useEffect(() => {
    fetch("https://www.breakingbadapi.com/api/episodes?series=Breaking+Bad")
      .then((resp) => resp.json())
      .then((content) => setData(content));
  }, []);

  useEffect(() => {
    const svg = select(svgRef.current);
    if (!dimensions) return;

    const minDate = min(data, (episode) => getDate(episode.air_date));
    const maxDate = max(data, (episode) => getDate(episode.air_date));

    const xScale = scaleTime().domain([minDate, maxDate]).range([10, 1520]);

    const yScale = scaleLinear()
      .domain([max(data, (episode) => episode.characters.length), 0])
      .range([10, dimensions.height - 170]);

    // svg
    //   .selectAll(".episode")
    //   .data(data)
    //   .join("line")
    //   .attr("class", "episode")
    // .attr("stroke", (episode) =>
    //   episode.characters.includes("Walter White") ? "blue" : "black"
    // )
    //   .attr("x1", (episode) => xScale(getDate(episode.air_date)))
    //   .attr("y1", dimensions.height)
    //   .attr("x2", (episode) => xScale(getDate(episode.air_date)))
    //   .attr("y2", (episode) => yScale(episode.characters.length));

    // rectangle
    svg
      .selectAll(".episode")
      .data(data)
      .join("rect")
      .attr("class", "episode")
      .attr("x", (episode) => xScale(getDate(episode.air_date)))
      .attr("y", (episode) => yScale(episode.characters.length))
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("width", 12)
      .attr("height", 6)
      .attr("fill", "teal")
      .attr("stroke-linecap", "round")
      .attr("stroke", (episode) =>
        episode.characters.includes("Walter White") ? "white" : "blue"
      )
      .append("title")
      .text((d) => d.title);

    const xAxis = axisBottom(xScale);
    svg.select(".x-axis").call(xAxis);
  }, [data, dimensions]);

  return (
    <div ref={wrapperRef} className="time-lineChart">
      <svg ref={svgRef}>
        <g className="x-axis" />
      </svg>
    </div>
  );
}
