import GeoChart from "../geoChart/GeoChart";
import "./locations.css";

function Locations() {
  return (
    <div className="locations">
      <div className="location-header">
        <h2>Locations</h2>
        <span className="section-header">
          <span>154 found, view in</span>
          <input type="radio" name="location_view" id="map" value="map" />
          <label htmlFor="map">map</label>
          <input type="radio" name="location_view" id="list" value="list" />
          <label htmlFor="list">list</label>
        </span>
      </div>
      <div className="location-globe">
        <GeoChart />
      </div>
    </div>
  );
}

export default Locations;
