import {
  select,
  json,
  forceCenter,
  forceLink,
  forceManyBody,
  forceSimulation,
} from "d3";
import React, { useEffect, useRef } from "react";
import "./networkChart.css";

// set the dimensions and margins of the graph
const margin = { top: 10, right: 30, bottom: 30, left: 40 };
const width = 688 - margin.left - margin.right; // 688 // 288
const height = 2200; // 2200 // 400

function NetworkChart({ dataQuery, type }) {
  const svgRef = useRef();

  useEffect(() => {
    // Sudah ada element g belum?
    if (svgRef.current.children[0]) {
      svgRef.current.children[0].remove();
    }

    const svg = select(svgRef.current)
      .attr("viewBox", [0, 0, width, height])
      .append("g");

    json(`https://app01.tmsdev.space/api/datanews`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({ query: dataQuery }),
    }).then(function (data) {
      // console.log(data.nodes, "ini data di network chart");
      const link = svg
        .selectAll("line")
        .data(data.links)
        .join("line")
        .style("stroke", "#aaa");
      // Initialize the nodes
      const node = svg
        .selectAll("circle")
        .data(data.nodes)
        .join("circle")
        .attr("r", 5)
        .style("fill", "#69b3a2");

      node.append("title").text((d) => d.name);

      // Let's list the force we wanna apply on the network
      const simulation = forceSimulation(data.nodes)
        .force(
          "link",
          forceLink()
            .id(function (d) {
              return d.id;
            })
            .links(data.links)
        )
        .force("charge", forceManyBody())
        .force("center", forceCenter(width / 2, height / 2));

      simulation.on("tick", () => {
        link
          .attr("x1", function (d) {
            return d.source.x;
          })
          .attr("y1", function (d) {
            return d.source.y;
          })
          .attr("x2", function (d) {
            return d.target.x;
          })
          .attr("y2", function (d) {
            return d.target.y;
          });

        node
          .attr("cx", function (d) {
            return d.x + 6;
          })
          .attr("cy", function (d) {
            return d.y - 6;
          });
      });
    });
  }, [dataQuery]);

  return (
    <>
      <div className="networkChart">
        <svg ref={svgRef}></svg>
      </div>
    </>
  );
}

export default NetworkChart;
