import { useState } from "react";
import "./detail.css";

function Detail({ detailItem }) {
  const formatDate = (date) => {
    const newDate = new Date(date).toLocaleString("id-ID", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return newDate;
  };
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="detail" onClick={() => setIsOpen(!isOpen)}>
        <h3>{detailItem.name}</h3>
      </div>
      {isOpen && (
        <div className="detail-desc">
          <span className="timeStamp">{formatDate(detailItem.date)}</span>
          <p>{detailItem.summary}</p>
          <span className="fullArticle">
            <span>
              <i>Kategori:</i> {detailItem.category}
            </span>
            <a href={detailItem.source} target="_blank" rel="noreferrer">
              More...{" "}
            </a>
          </span>
        </div>
      )}
    </>
  );
}

export default Detail;
