import CloudChart from "../cloudChart/CloudChart";
import "./topics.css";

function Topics({ dataQuery }) {
  return (
    <div className="topics">
      <div className="topic-header">
        <span className="cloudTabSelected">Topics</span>
        <span className="cloudTab">People</span>
        <span className="cloudTab">Companies</span>
        <span className="cloudTab">Organizations</span>
      </div>
      <div className="topic-cloud">
        <CloudChart dataQuery={dataQuery} />
      </div>
    </div>
  );
}

export default Topics;
