import "./header.css";
import { Assignment, Home, Info, Search, Twitter } from "@material-ui/icons";
// import { useRef } from "react";

function Header({ query, setQuery, setData, setType }) {
  // const selectRef = useRef();

  const handleClick = (e) => {
    e.preventDefault();
    setData(query);
    // setType(selectRef.current.value);
  };
  return (
    <div className="header">
      {/* Left */}
      <div className="headerLeft">
        <div className="headerLeftLogo">
          <Assignment className="icon" style={{ fontSize: 30 }} />
          <h1 className="logoTitle">
            TMS <b>News</b> Watch
          </h1>
        </div>
        <form className="search">
          <label htmlFor="inputText">recent news about</label>
          <input
            type="text"
            id="inputText"
            className="inputText"
            placeholder="Search..."
            onChange={(e) => setQuery(e.target.value)}
          />
          {/* <label htmlFor="as">as</label>
          <select name="as" id="as" defaultValue={""} ref={selectRef}>
            <option disabled value="">
              select type...
            </option>
            <option value="person">a person</option>
            <option value="company">a company</option>
            <option value="organization">an organization</option>
          </select> */}
          <button className="btn" onClick={handleClick}>
            Temukan
          </button>
        </form>
      </div>
      {/* Right */}
      <div className="headerRight">
        <Search className="icon" />
        <Home className="icon" />
        <Info className="icon" />
        <Twitter className="icon" />
      </div>
    </div>
  );
}

export default Header;
